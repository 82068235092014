import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

const Markdown = ({children, ...props}) => <>
  <ReactMarkdown
    components={{
      img: ({node, ...props}) => <>
        <img 
          src={process.env.GATSBY_API_URL + node.properties.src}
          alt={node.properties.alt}
        />
        <p class="content-image-caption">Image: {node.properties.alt}</p>
      </>,
      a: ({node, ...props}) => node.properties.href.charAt(0) === '/' ?
        <Link to={node.properties.href} {...props} /> :
        <a href={node.properties.href} {...props} target="_blank" />,
    }} {...props}
  >{children}</ReactMarkdown>
</>


export default Markdown;
