import * as React from 'react';
import Markdown from './../markdown';

import './basicText.scss';

const BasicText = ({data}) => {

  return (
    <>
      <div class="content-text">
        <Markdown>{data.text || data.basicText}</Markdown>
      </div>
    </>
  )
}

export default BasicText;
